@import "./variables.scss";
//variables
//operators
//nesting
//mixin
@mixin flex_st{
    display: flex;
    justify-content: center;
    align-items: center;
}
/* File: font.css */
@font-face {
    font-family: $font-family;
    src: local('CustomFont1Name'),
         url('../fonts/Poppins-Regular.ttf') format('ttf'),
         url('../fonts/Poppins-Bold.ttf') format('woff2'),
         url('../fonts/Poppins-Medium.ttf') format('woff2');
}
a{
    text-decoration:none !important;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $font-family;
}
//scrollbar style
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:$gredient_bg;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  
.DateTime{
   display: flex;
   justify-content: space-around;
   color: $blue;
   margin-bottom: 10px;
}
//menu-style-start
.offcanvas {
    background-image: url(../images/background_pattern.png);
    background-repeat: no-repeat;
    background-position: -51px 99%;
    width: 15% !important;
    background-size: 122% 31%;
}
.offcanvas-body {
    padding: 0px !important;
    margin-top: 30px;
   // @include flex_st;
}
.offcanvas-header {
    background: url(../images/SWR-logo.svg);
    background-repeat: no-repeat;
    width: 100%;
    margin-right: 10%;
    background-size: 69%;
    background-position: 9% 13%;
    position: relative;
    top: 7px;
}
.text-truncate{
    background: white;
    font-size: $menu-tab-font;
    width:$full-width;
    color:black;
    padding:5px 5px;
    font-size: $paragraph-text-size;
    font-weight: bold;  
    
}
.list-left-icon {
    float: left;
    position: relative;
    top: 7px;
    margin-right: 3px;
    left: -11px;
}
.list-group-item .text-truncate{
    background: $gredient_bg;
    font-size: $menu-tab-font;
    width:$full-width;
    font-size: $paragraph-text-size;
    color:white;
    padding:5px 5px;
    border: 1px solid #058A28;
    
}
.list-group-item a{
    width: 100%;
    display: flex;
    flex-direction: column; 
}
button.btn-close {
    border: 1px solid green;
}
.text-truncate span{
    position: relative;
    left: 21%;
}
.Headertop {
    height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.Header_title{
    font-size: 24px;
    text-transform: CAPITALIZE;
    font-weight: bold;
}
.train_service_text{
    font-size: 16px;
    color: $gredient_bg;
}
.train_ser_txt_clr{
    color:#058A28;
}
.datetime_box {
    display: flex;
    flex-flow: column;
    color:$blue;
}
.customize_table ul {
    display: flex;
    justify-content: space-around;
}
.customize_table ul li{
    list-style: none;
    padding-left: 5px;
    cursor:pointer;
}
.gap-2 {
    gap: 0.5rem!important;
    display: flex;
    justify-content: center;
}
.customize_table {
    margin-top: 18px;
}

